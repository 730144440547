<template>
  <div>
    <!--轮播图-->
    <carousel :lunboData="lunboData"></carousel>
    <div class="cont-warp">
      <!-- 培训 -->
      <img src="../../assets/images/securityTrain/pc/pic01.png" class="distance-top"/>
      <div class="item-cont">
        <div class="item">
          <router-link to="/cdsoPage">
            <div class="item-top back01">
              <div class="text">
                <p>注册数据安全官</p>
                <p>CDSO</p>
              </div>
            </div>
            <div class="item-text">
              <div class="text-p">
                <p>着眼于数据安全顶层规划设计与统筹管理，旨在培养业界一流的数据安全领导者。</p>
              </div>
              <p class="btn back01">查看详情 》</p>
            </div>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/cdsePage">
            <div class="item-top  back02">
              <div class="text">
                <p>注册数据安全工程师</p>
                <p>CDSE</p>
              </div>
            </div>
            <div class="item-text">
              <div class="text-p">
                <p>着眼于数据安全顶层规划设计与统筹管理，旨在培养业界一流的数据安全领导者。</p>
              </div>
              <p class="btn back02">查看详情 》</p>
            </div>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/dsmmPage">
            <div class="item-top back03">
              <div class="text">
                <p>DSMM测评师</p>
              </div>
            </div>
            <div class="item-text">
              <div class="text-p">
                <p>着眼于DSMM（数据安全能力成熟度模型）测试评估，旨在培养专业的DSMM测评师。</p>
              </div>
              <p class="btn back03">查看详情 》</p>
            </div>
          </router-link>
        </div>
        <div class="item no-click">
          <div class="item-top back04">
            <div class="text">
              <p>定制班培养</p>
            </div>
          </div>
          <div class="item-text">
            <div class="text-p">
              <p>行业不同，数据要素不同；产业不同，数据安全实施方案不同；角色不同，数据安全岗位职责不同。</p>
              <p>我们可根据您的需求量身定做。</p>
              <p>请联系电话：186-1273-3266</p>
            </div>
            <div class="btn back04 copyBtn" :data-clipboard-text="phone" @click="copy">
              复制号码 》
            </div>
          </div>
        </div>
      </div>
      <!-- 认证 -->
      <img src="../../assets/images/securityTrain/pc/pic02.png" class="distance-top"/>
      <div class="item-cont">
        <div class="approve-item">
          <h3 class="s1">数据安全评估</h3>
          <p>根据国家标准GB/T37988-2019《信息安全技术数据安全能力成熟度模型》，评估企业或组织的数据安全能力成熟度等级，帮助企业或组织识别数据安全风险，为企业或组织的数据安全治理能力提供标准依据与背书。</p>
          <router-link to="/assessPage"><p class="btns back01">查看详情 》</p></router-link>
        </div>
        <div class="approve-item">
          <h3 class="s2">数据安全认证</h3>
          <p>根据国家标准GB/T 37988-2019《信息安全技术数据安全能力成熟度模型》，开展数据安全能力成熟度测评相关工作，对企业或组织颁发，<span
              class="red">国家认监委认证的数据安全能力成熟度等级证书</span>。</p>
          <router-link to="/approvePage"><p class="btns back02">
            查看详情 》
          </p></router-link>
        </div>
      </div>
      <!--优势特色-->
      <div class="edge-features-cont">
        <div class="edge-cont">
          <div class="edge-item">
            <div class="edge-name">
              <p>国家级别</p>
            </div>
            <div class="edge-tip-img">
              <img src="../../../src/assets/images/securityTrain/pc/pic03-tip.png">
            </div>
            <div class="edge-text">
              <p>数据安全领域<span class="red">第一个国家级研究中心 </span></p>
              <p>国内数据安全领域<span class="red">第一个国家级标准</span></p>
            </div>
            <router-link to="/about" class="edge-right"><span>查看详情</span></router-link>
          </div>
          <div class="edge-item">
            <div class="edge-name">
              <p>权威认证</p>
            </div>
            <div class="edge-tip-img">
              <img src="../../../src/assets/images/securityTrain/pc/pic03-tip.png">
            </div>
            <div class="edge-text">
              <p>最早：国家认监委批复的国内<span class="red">第一块DSMM牌照</span></p>
              <p>最优：国家信安标委优秀实践案例评选<span class="red">一等奖</span></p>
            </div>
            <router-link to="/approvePage" class="edge-right"><span>查看详情</span></router-link>
          </div>
          <div class="edge-item">
            <div class="edge-name">
              <p>顶级师资</p>
            </div>
            <div class="edge-tip-img">
              <img src="../../../src/assets/images/securityTrain/pc/pic03-tip.png">
            </div>
            <div class="edge-text">
              <p><span class="red">权威</span>标准化专家、<span class="red">资深</span>认证专家</p>
              <p><span class="red">顶级</span>企业大咖、<span class="red">前沿</span>学者教授</p>
            </div>
            <router-link to="/teacherPage" class="edge-right"><span>查看详情</span></router-link>
          </div>
          <div class="edge-item">
            <div class="edge-name">
              <p>理实结合</p>
            </div>
            <div class="edge-tip-img">
              <img src="../../../src/assets/images/securityTrain/pc/pic03-tip.png">
            </div>
            <div class="edge-text">
              <p>理论联系实际、<span class="red">最前沿</span>标准引领</p>
              <p>最佳实践案例第一个国家级标准></p>
            </div>
            <router-link to="/coursePage" class="edge-right"><span>查看详情</span></router-link>
          </div>
          <div class="edge-item">
            <div class="edge-name">
              <p>一考双证</p>
            </div>
            <div class="edge-tip-img">
              <img src="../../../src/assets/images/securityTrain/pc/pic03-tip.png">
            </div>
            <div class="edge-text">
              <p>获得研究中心证书</p>
              <p>获得工信部人才证书</p>
            </div>
            <router-link to="/advantage" class="edge-right"><span>查看详情</span></router-link>
          </div>
        </div>
      </div>
      <img src="../../assets/images/securityTrain/pc/pic04.png" class="distance-top"/>
      <img src="../../assets/images/securityTrain/pc/pic05.png" class="distance-top"/>
      <!-- 报名二维码-->
      <erweima></erweima>
    </div>
  </div>
</template>

<script>
import Carousel from '../../components/carousel'
import Erweima from './pub/erweima'
import Clipboard from 'clipboard'

export default {
  name: '',
  components: {
    Carousel,
    Erweima
  },
  data() {
    return {
      phone: "186-1273-3266",
      //轮播图数据
      lunboData: [
        {
          imgUrl: require("../../../src/assets/images/securityTrain/pc/banner01.png"),
          pathUrl: "/cdsoPage",
          id: 1
        },
        {
          imgUrl: require("../../../src/assets/images/securityTrain/pc/banner02.png"),
          pathUrl: "/cdsePage",
          id: 2
        },
        {
          imgUrl: require("../../../src/assets/images/securityTrain/pc/banner03.png"),
          pathUrl: "/dsmmPage",
          id: 3
        },
        {
          imgUrl: require("../../../src/assets/images/securityTrain/pc/banner04.png"),
          pathUrl: "/approvePage",
          id: 4
        },
        {
          imgUrl: require("../../../src/assets/images/securityTrain/pc/banner05.png"),
          pathUrl: "",
          id: 5
        },
        {
          imgUrl: require("../../../src/assets/images/securityTrain/pc/banner06.png"),
          pathUrl: "",
          id: 6
        },
      ]
    }
  },
  methods: {
    copy() {
      let clipboard = new this.Clipboard('.copyBtn')
      clipboard.on('success', () => {
        this.$message.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.error('复制失败')
        clipboard.destroy()
      })
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('h5SecurityTrain');
    } else {
      this.$router.replace('securityTrain');
    }
  },
}
</script>
<style>
.h5-pay-cost-page{
  display: none;
}
</style>
<style lang="less" scoped>
.cont-warp {
  width: 1200px;
  margin: 0 auto;
  padding: 80px 0;

  .distance-top {
    margin-top: 80px;
  }

  img {
    max-width: 100%;
    //margin-top: 80px;
  }

  .item-cont {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    //培训
    .item {
      width: 24%;
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      border-radius: 10px;
      cursor: pointer;


      &:hover {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px 0px rgba(0, 121, 244, 0.45);
        border-radius: 10px;
      }

      .item-top {
        width: 100%;
        height: 111px;
        border-radius: 10px;
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: center;
        -webkit-box-align: center;

        p {
          font-size: 22px;
          color: #FFFFFF;
          line-height: 30px;
        }
      }

      .item-text {
        padding: 20px;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 23px;

        .text-p {
          text-align: left;
          height: 120px;
        }
      }

      .btn {
        width: 130px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
        color: #fff;
        margin-left: 25%;
        margin-top: 10px;
      }
    }

    .back01 {
      background: #0079F4;
      color: #fff !important;
    }

    .back02 {
      background: #F79D0F;
      color: #fff !important;
    }

    .back03 {
      background: #0EC6D2;
    }

    .back04 {
      background: #7C51F8;
    }

    //认证
    .approve-item {
      width: 47%;
      background: #FFFFFF;
      box-shadow: 0px 0px 21px 0px rgba(23, 11, 0, 0.05);
      border-radius: 10px;
      padding: 40px 30px;
      margin-right: 30px;

      &:hover {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px 0px rgba(0, 121, 244, 0.45);
        border-radius: 10px;
      }

      &:nth-child(2n) {
        margin-right: 0px;
      }

      h3 {
        text-align: left;
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        line-height: 35px;
        margin-bottom: 25px;
      }

      .s1 {
        &:before {
          content: "";
          display: inline-block;
          width: 31px;
          height: 27px;
          background: url("../../../src/assets/images/securityTrain/pc/icon01.png");
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      .s2 {
        &:before {
          content: "";
          display: inline-block;
          width: 27px;
          height: 27px;
          background: url("../../../src/assets/images/securityTrain/pc/icon02.png");
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      p {
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
      }

      .btns {
        width: 130px;
        height: 50px;
        border-radius: 25px;
        line-height: 50px;
        text-align: center;
        margin-top: 44px;

        a {
          display: inline-block;
          color: #FFFFFF;
        }
      }
    }
  }

  .red {
    color: #FF3434;
  }

  //优势特色
  .edge-features-cont {
    width: 100%;
    height: 1314px;
    background: url("../../../src/assets/images/securityTrain/pc/pic03.png") no-repeat;
    background-size: 100% 1314px;
    position: relative;
    margin-top: 50px;

    .edge-cont {
      position: absolute;
      top: 201px;
      left: 6%;

      .edge-item {
        width: 1019px;
        //height: 162px;
        background: #FFFFFF;
        border-radius: 81px;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        transition: 0.3s all ease;

        &:hover {
          transition: 0.3s all ease;
          -webkit-transform: translate(-15px, 0);
          -moz-transform: translate(-15px, 0);
          transform: translate(-15px, 0);
        }

        .edge-name {
          background: url("../../../src/assets/images/securityTrain/pc/pic03-left.png") no-repeat;
          width: 157px;
          height: 141px;
          position: relative;

          p {
            position: absolute;
            top: 35px;
            left: 40px;
            width: 72px;
            height: 78px;
            font-size: 36px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 43px;
          }
        }

        .edge-tip-img {
          margin-top: 50px;
        }

        .edge-text {
          width: 60%;
          padding-top: 30px;

          p {
            font-size: 26px;
            color: #000000;
            line-height: 42px;
            text-align: left;
          }
        }

        .edge-right {
          width: 124px;
          height: 124px;
          background: #E7EEF6;
          border-radius: 50%;
          position: relative;
          margin-top: 10px;
          cursor: pointer;

          span {
            width: 44px;
            height: 49px;
            font-size: 22px;
            line-height: 28px;
            position: absolute;
            top: 30%;
            left: 33%;
          }
        }
      }
    }

  }
}

.no-click {
  cursor: text !important;
}

</style>
